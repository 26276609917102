'use client';
import { createStore } from 'zustand';
import { createContext, useRef } from 'react';
import { useRouter, useParams } from 'next/navigation';
import { TFloorMatDataDB } from '@/lib/db/seat-covers';
import { TPathParams, TQueryParams, getCompleteSelectionData } from '@/utils';
import { compareRawStrings, isFullSet } from '@/lib/utils';
import { TProductReviewSummary, TReviewData } from '@/lib/types/review';

type FloorMatSelectionStore = ReturnType<typeof createFloorMatSelectionStore>;

export type TQuery = {
  type: string;
  coverType: string;
  year: string;
  make: string;
  model: string;
  submodel: string; // Get rid of this in the future
  secondSubmodel: string; // Get rid of this in the future
  thirdSubmodel: string; // Get rid of this in the future
  submodel1: string;
  submodel2: string;
  submodel3: string;
  parent_generation: string;
};

interface IFloorMatProps {
  modelData: TFloorMatDataDB[];
  reviewData: TReviewData[];
  reviewDataSummary: TProductReviewSummary;
  reviewImages: TReviewData[];
}

export interface IFloorMatSelectionState extends IFloorMatProps {
  selectedProduct: TFloorMatDataDB;
  setSelectedProduct: (newProduct: TFloorMatDataDB) => void;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  query: TQuery;
  setQuery: (newQuery: Partial<TQuery>) => void;
  isComplete: boolean;
  setReviewData: (newReviewData: TReviewData[]) => void;
  addReviewData: (data: TReviewData[]) => void;
  setReviewDataSummary: (newReviewDataSummary: TProductReviewSummary) => void;
  reviewImageTracker: Record<string, boolean>;
  setReviewImageTracker: (newImageTracker: Record<string, boolean>) => void;
  availableColors: string[];
  setAvailableColors: (availableColors: string[]) => void;
  selectedSetDisplay: string;
  setSelectedSetDisplay: (selectedSet: string) => void;
  selectedProducts: TFloorMatDataDB[];
  addProduct: (product: TFloorMatDataDB) => void;
  removeProduct: (productId: number) => void;
  clearSelectedProducts: () => void;
}

type FloorMatSelectionStoreParams = {
  modelData: TFloorMatDataDB[];
  params: TPathParams;
  searchParams: TQueryParams | undefined;
  initialReviewData: TReviewData[];
  initialReviewDataSummary: TProductReviewSummary;
  initialReviewImages: TReviewData[];
};

const createFloorMatSelectionStore = ({
  modelData,
  params,
  searchParams,
  initialReviewData,
  initialReviewDataSummary,
  initialReviewImages,
}: FloorMatSelectionStoreParams) => {
  const customerSelectedYear =
    typeof window !== 'undefined'
      ? localStorage?.getItem('heroDropdownYear')
      : '';
  // TODO: - This should just be a DB call but need to add submodel1_slug column
  const modelDataWithFilteredSubmodelSelection = searchParams?.submodel
    ? modelData.filter((model) =>
        compareRawStrings(model.submodel1, searchParams.submodel as string)
      )
    : modelData;

  const modelDataWithFilteredSubmodel2Selection = searchParams?.submodel2
    ? modelDataWithFilteredSubmodelSelection.filter((model) =>
        compareRawStrings(model.submodel2, searchParams.submodel2 as string)
      )
    : modelDataWithFilteredSubmodelSelection;

  const modelDataWithFilteredSubmodel3Selection = searchParams?.submodel3
    ? modelDataWithFilteredSubmodelSelection.filter((model) =>
        compareRawStrings(model.submodel3, searchParams.submodel3 as string)
      )
    : modelDataWithFilteredSubmodel2Selection;

  const reviewImageTracker: Record<string, boolean> = {};

  initialReviewData.forEach((reviewData) => {
    !!reviewData.review_image &&
      reviewData.review_image.split(',').map((imageUrl) => {
        if (!reviewImageTracker[imageUrl]) {
          reviewImageTracker[imageUrl] = true;
        }
      });
  });

  const initialQueryState = {
    year: (params?.year && customerSelectedYear) || '',
    type: params?.productType ?? '',
    coverType: params?.coverType ?? 'leather',
    make: params?.make ?? '',
    model: params?.model ?? '',
    submodel: searchParams?.submodel ?? '',
    secondSubmodel: searchParams?.submodel2 ?? '',
    thirdSubmodel: searchParams?.submodel3 ?? '',
    submodel1: searchParams?.submodel ?? '',
    submodel2: searchParams?.submodel2 ?? '',
    submodel3: searchParams?.submodel3 ?? '',
    parent_generation: '',
  };

  const {
    completeSelectionState: { isComplete },
  } = getCompleteSelectionData({
    data: modelDataWithFilteredSubmodel3Selection,
  });

  const firstAvailableSet = modelDataWithFilteredSubmodel3Selection.filter(
    (FloorMat) =>
      isFullSet(FloorMat?.display_set ?? '') ===
      isFullSet(modelDataWithFilteredSubmodel3Selection[0]?.display_set ?? '')
  );

  const firstAvailableMat = modelDataWithFilteredSubmodel3Selection.find(
    (item) => item.display_set === 'Floor Mats'
  );

  const availableColors = new Set(['gray', 'black', 'beige']);
  const firstAvaiableColor = firstAvailableSet
    .map((FloorMat) => FloorMat?.display_color?.toLowerCase() ?? '')
    .filter((color) => availableColors.has(color));

  return createStore<IFloorMatSelectionState>()((set, get) => ({
    modelData: modelDataWithFilteredSubmodel3Selection,
    query: initialQueryState,
    setQuery: (newQuery: Partial<TQuery>) => {
      set((state) => ({
        ...state,
        query: {
          ...state.query,
          ...newQuery,
        },
      }));
    },
    selectedProduct: modelDataWithFilteredSubmodel3Selection[0],
    setSelectedProduct: (newProduct: TFloorMatDataDB) => {
      set(() => ({
        selectedProduct: newProduct,
        featuredImage: newProduct?.product?.split(',')[0] ?? '',
      }));
    },
    selectedColor:
      modelDataWithFilteredSubmodel3Selection[0]?.display_color ?? '',
    setSelectedColor: (newColor: string) =>
      set(() => ({ selectedColor: newColor })),
    isComplete,
    reviewImages: initialReviewImages,
    reviewImageTracker,
    setReviewImageTracker: (newImageTracker: Record<string, boolean>) => {
      set(() => ({
        reviewImageTracker: newImageTracker,
      }));
    },
    reviewData: initialReviewData,
    setReviewData: (newReviewData: TReviewData[]) => {
      set(() => ({ reviewData: newReviewData }));
    },
    addReviewData: (data: TReviewData[]) =>
      set((state) => ({ reviewData: [...state.reviewData, ...data] })),
    reviewDataSummary: initialReviewDataSummary,
    setReviewDataSummary: (newReviewDataSummary: TProductReviewSummary) => {
      set(() => ({ reviewDataSummary: newReviewDataSummary }));
    },
    setReviewsWithImages: (newReviewImages: TReviewData[]) => {
      set(() => ({ reviewImages: newReviewImages }));
    },
    availableColors: firstAvaiableColor,
    setAvailableColors: (newAvailableColors: string[]) =>
      set(() => ({ availableColors: newAvailableColors })),
    selectedSetDisplay: isFullSet(
      modelDataWithFilteredSubmodel3Selection[0]?.display_set ?? ''
    ),
    setSelectedSetDisplay: (selectedSet: string) => {
      set(() => ({ selectedSetDisplay: selectedSet }));
    },
    selectedProducts: [firstAvailableMat],
    addProduct: (product) =>
      set((state) => {
        return {
          selectedProducts: [...state.selectedProducts, product],
        };
      }),
    removeProduct: (productId) =>
      set((state) => ({
        selectedProducts: state.selectedProducts.filter(
          (p) => p.id !== productId
        ),
      })),
    clearSelectedProducts: () =>
      set(() => ({
        selectedProducts: [],
      })),
  }));
};

export const FloorMatSelectionContext =
  createContext<FloorMatSelectionStore | null>(null);

const FloorMatSelectionProvider = ({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: any;
}) => {
  const {
    modelData,
    params,
    searchParams,
    reviewData,
    reviewDataSummary,
    reviewImages,
  } = initialState;
  const router = useRouter();

  if (modelData.length === 0) {
    router.push('/404');
  }

  const store = useRef(
    createFloorMatSelectionStore({
      modelData,
      params,
      searchParams,
      initialReviewData: reviewData as TReviewData[],
      initialReviewDataSummary: reviewDataSummary,
      initialReviewImages: reviewImages,
    })
  ).current;
  return (
    <FloorMatSelectionContext.Provider value={store}>
      {children}
    </FloorMatSelectionContext.Provider>
  );
};

export default FloorMatSelectionProvider;
